import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from '../pages/Login/Login.jsx';
import ErrorPage from '../pages/Error/Error.jsx';
import Home from '../pages/Home/Home.jsx';
import ApiDoc from '../pages/Docs/Docs.jsx';

export default function Router({ isLoggedIn, setIsLoggedIn, setUserDetails }) {
    const unAuthorizedRoutes = [
        {
            path: '/',
            element: <Login setIsLoggedIn={setIsLoggedIn} setUserDetails={setUserDetails} />,
            errorElement: <ErrorPage />
        },
    ];

    const authorizedRoutes = [
        {
            path: '/',
            element: <Home setIsLoggedIn={setIsLoggedIn} />,
            children: [
                {
                    path: '/',
                    element: <ApiDoc />
                }
            ],
            errorElement: <ErrorPage />
        }
    ];

    const router = createBrowserRouter(isLoggedIn ? authorizedRoutes : unAuthorizedRoutes);
    return <RouterProvider router={router} />;
}
