import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Card, CardContent, Box, Grid, Typography, Button } from '@mui/material';
import PDFViewer from 'pdf-viewer-reactjs';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import YAML from 'yaml';
import GetAppIcon from '@mui/icons-material/GetApp';
import CodeIcon from '@mui/icons-material/Code';

import DirectoryTree from '../../components/DirectoryTree';

function Docs() {
    const [content, setContent] = useState('');
    const [selectedContent, setSelectedContent] = useState('');
    const [selectedKey, setSelectedKey] = useState('');

    const handleSelect = (key, content) => {
        setSelectedKey(key);
        setSelectedContent(content);
    };

    const downloadYAML = () => {
        try {
            const yamlContent = YAML.stringify(YAML.parse(selectedContent));
            const blob = new Blob([yamlContent], { type: 'application/yaml' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${selectedKey.replace(/\\s+/g, "").replace(">", "_to_").trim()}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error converting YAML:', error);
        }
    };

    const downloadJSON = () => {
        try {
            const jsonObject = YAML.parse(selectedContent);
            const jsonContent = JSON.stringify(jsonObject, null, 2);
            const blob = new Blob([jsonContent], { type: 'application/json' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${selectedKey.replace(">", "_to_").replace(".yml", "").replace(/\\s+/g, "").trim()}.json`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error converting YAML to JSON:', error);
        }
    };

    const renderFile = () => {
        const fileExtension = selectedKey.split('.').pop();
        switch (fileExtension) {
            case 'md':
                return <Markdown rehypePlugins={[rehypeRaw]}>{selectedContent}</Markdown>;
            case 'yml':
            case 'yaml':
                return (
                    <div>
                        <div style={{ textAlign: 'right', marginTop: '20px' }}>
                            <Button variant="contained" color="primary" onClick={downloadYAML} startIcon={<GetAppIcon />} style={{ marginRight: '10px' }}>
                                Download YAML
                            </Button>
                            <Button variant="contained" color="secondary" onClick={downloadJSON} startIcon={<CodeIcon />}>
                                Download JSON
                            </Button>
                        </div>
                        <SwaggerUI spec={YAML.parse(selectedContent)} deepLinking={true} />
                    </div>
                );
            case 'pdf':
                return <PDFViewer document={{ base64: selectedContent }} />;
            default:
                return <Typography>{selectedContent || "Select a file to view its contents."}</Typography>;
        }
    };

    useEffect(() => {
        const fetchSwaggerJson = async () => {
            try {
                const token = localStorage.getItem('tokenDetails') ? JSON.parse(localStorage.getItem('tokenDetails')).token : '';
                const response = await axios.get('/api/v1/docs/api', {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                });
                setContent(response.data);
            } catch (err) {
                setSelectedContent(err?.response?.data?.message || 'Unable to fetch API documentation');
            }
        };

        fetchSwaggerJson();
    }, []);

    return (
        <Grid container sx={{ display: 'flex', minHeight: '100vh', backgroundColor: 'rgb(232, 232, 232)' }}>
            <Grid item xs={3} sx={{ overflow: 'auto' }}>
                <DirectoryTree data={content} onSelect={handleSelect} />
            </Grid>
            <Grid item xs={9} sx={{ overflow: 'auto' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', m: 4 }}>
                    <Card sx={{ minWidth: '100%' }}>
                        <CardContent>
                            {renderFile()}
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Docs;
