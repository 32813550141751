import { redirect, useRouteError } from 'react-router-dom'

export default function Error() {

    const { error } = useRouteError()

    if (!error) {
        return redirect('/')
    }
    return (
        <div>
            <h1>404</h1>
            <p>{error.statusText || error.message}</p>
            <a href='/'>Go Home</a>
        </div>
    )


}