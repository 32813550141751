import React, { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';

import Router from './router/router.jsx';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: 'transparent',
          backgroundImage: 'linear-gradient(180deg, #4eb855 0%, #a4d96c 100%)',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
        },
      },
    },
  },
});

function App() {
  const [tokenDetails, setTokenDetails] = useState(localStorage.getItem('tokenDetails') ? JSON.parse(localStorage.getItem('tokenDetails')) : null);
  const isLoggedIn = tokenDetails && tokenDetails.token;

  const onHandleSetIsLoggedIn = (tokenDetails) => {
    localStorage.setItem('tokenDetails', JSON.stringify(tokenDetails));
    setTokenDetails(tokenDetails);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router setIsLoggedIn={onHandleSetIsLoggedIn} isLoggedIn={isLoggedIn} />
    </ThemeProvider>
  );
}

export default App;
