import React from 'react';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { styled } from '@mui/material/styles';

const StyledListItemButton = styled(ListItemButton)(({ theme, level }) => ({
  paddingLeft: theme.spacing(2 + level * 2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  display: 'flex', // Ensuring it uses flex layout
  alignItems: 'center', // Center items vertically
}));

const DirectoryTree = ({ data, onSelect }) => {
  const [open, setOpen] = React.useState({});
  const [selected, setSelected] = React.useState(null);

  const handleClick = (path) => {
    setOpen(prev => ({ ...prev, [path]: !prev[path] }));
  };

  const handleSelect = (path, key, content) => {
    setSelected(path);
    onSelect(key, content);
  };

  const renderTree = (node, path = '', level = 0) => {
    return Object.entries(node).map(([key, value]) => {
      const newPath = path + '/' + key;
      const isFolder = typeof value === 'object' && value !== null && Object.keys(value).length > 0;

      return (
        <React.Fragment key={newPath}>
          <StyledListItemButton
            level={level}
            onClick={() => isFolder ? handleClick(newPath) : handleSelect(newPath, key, value)}
            selected={selected === newPath}
          >
            {isFolder ? (open[newPath] ? <ExpandLess /> : <ExpandMore />) : null}
            <ListItemIcon>
              {isFolder ? <FolderIcon /> : <InsertDriveFileIcon />}
            </ListItemIcon>
            <ListItemText sx={{ width: '100vw' }} primary={key.split('_').pop().split('.').shift()} />
          </StyledListItemButton>
          {isFolder && (
            <Collapse in={open[newPath]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderTree(value, newPath, level + 1)}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };


  return (
    <List>
      {renderTree(data)}
    </List>
  );
};

export default DirectoryTree;
